<!--Template for the saven remedies website Header -->

<template>
      <v-footer id="footer-card">
        <v-card flat tile width="100%" class="red lighten-1 text-center">
          <v-divider></v-divider>
          <v-card-text class="white--text">
            Copyrights &copy; 2018- {{ new Date().getFullYear() }} — <strong>Saven Remedies.</strong>
          </v-card-text>
        </v-card>
      </v-footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
#footer-card {
    position: absolute;
    bottom: 0;
    width: 100%;
}
</style>
