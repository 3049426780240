<template>
  <div id="home-container">
    <div class="primary-title-container">
      <p class="primary-title">VISION</p>
    </div>
    <div class="primary-content-container">
      <div class="content-section left-only-justified">
        SAVEN REMEDIES VISION IS TO BE BEST IN PROVIDING QUALITY HEALTHCARE
        PRODUCTS AND SERVICES TOWARDS A WORLD OF HEALTHY AND HAPPY PEOPLE. OUR
        GOAL IS TO OFFER QUALITY PRODUCTS WITH AFFORDABLE PRICES. SAVEN STRIVES
        TO MAINTAIN WORLD CLASS STANDARDS IN OUR PRODUCTS.
      </div>
      <div class="content-section">
        OUR MOTO IS
        <span class="secondary-title-container">
          <span class="secondary-title">
            <span class="text-alt-color">SAVE</span> HUMA<span
              class="text-alt-color"
              >N</span
            >
          </span>
        </span>
        <p>
          CONTRIBUTE TO THE IMPROVEMENT OF HUMAN HEALTH AND TO MEET THE
          EXPECTATIONS OF OUR CUSTOMERS AND EMPLOYEES.
        </p>
      </div>
    </div>
  </div>
</template>
