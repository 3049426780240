<template>
  <div id="home-container">
    <div class="primary-title-container">
      <p class="primary-title">
        <span class="text-alt-color">SAVE</span> HUMA<span class="text-alt-color"
          >N</span
        >
      </p>
    </div>
    <div class="primary-content-container">
      <div class="content-section left-only-justified">
        SAVEN REMEDIES PRIVATE LIMITED is a proffessionally managed progressive
        pharmaceutical marketing company head quartered at Hyderabad, Telangana,
        India. We at Savenremedies formulate, develop and commercialize
        medicines with the AIM to provide affordable medicine needs to all.
      </div>
      <div class="content-section left-only-justified">
        Savenremedies focuses on pain management (Orals & Topicals), Nutritional
        and general medicines. Our vision is to be best in providing quality
        healthcare products and services towards a world of healthy and happy
        people.
      </div>

      <div class="content-section left-only-justified">
        SAVEN always strives to maintain world class standards in our products.
        All our products are manufactured in WHO GMP approved manufacturing
        facilities, equipped with the STATE-OF-ART equipment.
      </div>

      <div class="content-section left-only-justified">
        SAVEN will be contributing every bit of effort to the improvement of
        human health and to meet the expectations of CUSTOMERS and EMPLOYEES.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
  data: () => ({})
}
</script>
