<template>
  <div id="home-container">
    <div class="primary-title-container">
      <p class="primary-title">Products</p>
    </div>
    <div class="primary-content-container">
      <div class="content-sections-group">
        <div class="content-section">
          <div class="content-section-body">
            <ProductItem
              v-for="product of ProductCollection"
              :key="product.ProductName"
              :imgsrc="product.ProductImage"
              :name="product.ProductName"
              :description="product.ProductDescription"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from '../components/ProductItem.vue'

// Product Data

const ProductCollection = [
  {
    ProductName: 'CalsiVen',
    ProductImage: '/products/CALSIVEN 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'CalsiVen Plus',
    ProductImage: '/products/CALSIVEN PLUS  1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'Cobaven',
    ProductImage: '/products/COBAVEN 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'D3 VEN',
    ProductImage: '/products/D3 VEN 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'DEPOVEN',
    ProductImage: '/products/DEPOVEN 4 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'ETORVEN',
    ProductImage: '/products/ETORVEN 1.png',
    ProductDescription: ''
  },
  {
    ProductName: 'ETORVEN',
    ProductImage: '/products/ETORVEN 90 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'ETORVEN MR',
    ProductImage: '/products/ETORVEN MR 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'FERORAPID',
    ProductImage: '/products/FERORAPID 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'FERORAPID PLUS',
    ProductImage: '/products/FERORAPID PLUS 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'FLEXAVEN D',
    ProductImage: '/products/FLEXAVEN D 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'CGABAFLEX NT',
    ProductImage: '/products/GABAFLEX NT 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'GALIN SR 75',
    ProductImage: '/products/GALIN SR 75 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'IMMUSAFE-CZ',
    ProductImage: '/products/IMMUSAFE-CZ 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'RABIVEN D',
    ProductImage: '/products/RABIVEN D 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'RHUMAFAST GEL',
    ProductImage: '/products/RHUMAFAST GEL 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'RIDE FREE MR',
    ProductImage: '/products/RIDE FREE MR 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'RIDECORT 6',
    ProductImage: '/products/RIDECORT 6 1.jpg',
    ProductDescription: ''
  },
  {
    ProductName: 'RIDECORT 12',
    ProductImage: '/products/RIDECORT 12 2.jpg',
    ProductDescription: ''
  }
]

export default {
  name: 'ProductView',
  components: {
    ProductItem
  },
  data: () => ({
    ProductCollection
  })
}
</script>
