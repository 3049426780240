<template>
    <div id="home-container">
        <div class="primary-title-container">
            <p class="primary-title">Contact Us</p>
        </div>
        <div class="primary-content-container">
            <div class="content-section left-only-justified">
                For sales enquiries please reach us at
                <address>
                    Email : <a href="mailto:savenremedies@gmail.com">savenremedies@gmail.com</a><br />

                    SAVEN REMEDIES PRIVATE LIMITED<br />
                    3-6-100/B, BOOSAREDDY GUDA, W.M PALLY, SECUNDERABAD,<br />
                    Hyderabad, Telangana, India, 500026<br />
                </address>
            </div>
        </div>
    </div>
</template>
