<template>
    <div id="home-container">
      <div class="primary-title-container">
        <p class="primary-title">About Us</p>
      </div>
      <div class="primary-content-container">
        <div class="content-section left-only-justified">
            SAVEN REMEDIES PRIVATE LIMITED IS A PROFESIONALLY MANAGED PROGRESSIVE PHARMACEUTICAL MARKETING COMPANY HEAD QUARTETERED AT HYDERABAD,INDIA. INCORPORATED UNDER MINISTRY OF CORPORATE AFFAIRS(MCA).
        </div>
        <div class="content-section left-only-justified">

          WE FORMULATE ,DEVELOP AND COMMERCIALIZE MEDICINES WITH THE AIM TO PROVIDE AFFORDABLE MEDICINE NEEDS TO ALL.
          WE FOCUS ON PAIN MANAGEMENT (ORALS & TOPICALS),NUTRITIONAL AND GENERAL MEDICINES.
          ALL OUR PRODUCTS ARE MANUFACTURED IN WHO GMP APPROVED MANUFACTURING FACILITIES, EQUIPPED WITH THE STATE-OF-ART EQUIPMENT.
        </div>
      </div>
    </div>
  </template>
