<template>
  <div id="product-item" class="product-item">
    <img :src="imgsrc" :alt="description" class="product-image">
    <div class="product-info">
        <span>{{ name }}</span><br/>
    <span>{{ description }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgsrc: String,
    name: String,
    description: String,
    productCategory: String
  }
}
</script>
