<!--Template for the saven remedies website Header -->

<template>
  <v-app-bar id="topHeaderMenu" density="default">
    <v-img max-height="50" max-width="150" src="/assets/logo-new.png"></v-img>
    <v-spacer></v-spacer>
    <v-btn><router-link to="/">Home</router-link></v-btn>
    <v-btn><router-link to="/vision">Vision</router-link></v-btn>
    <v-btn><router-link to="/products">Products</router-link></v-btn>
    <v-btn><router-link to="/aboutus">About Us</router-link></v-btn>
    <v-btn><router-link to="/contactus">Contact Us</router-link></v-btn>
  </v-app-bar>
</template>
<script>
export default {
  name: 'Header'
}
</script>
