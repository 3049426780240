import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Vision from '../views/Vision'
import Products from '../views/Products'
import AboutUs from '../views/AboutUs'
import ContactUs from '../views/ContactUs'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vision',
    name: 'vision',
    component: Vision
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: ContactUs
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
